(function ($) {
    //*****************************************
    // Global Site Specific JS
    //*****************************************
    //
    //	Functions
    //	-> List the site functions and brief desc in this file
    //	-> thisIsAnExampleFunction - does some stuff
    //	-> aDifferentExampleFunction - does other stuff
    //
    //*****************************************


    //--------------------------------------------------------------------
    //   Doc Ready - There shouldnt be more than one of these in this file
    //--------------------------------------------------------------------

    $(document).ready(function (e) {

        //------------------------------------------
        //  reCaptcha Function
        //------------------------------------------

        /*window.imNotARobot = function() {
            document.querySelectorAll('.g-recaptcha-error').forEach(function(item){
                item.remove();;
            })
        }*/

        //------------------------------------------
        //  Phone Context Modal
        //------------------------------------------

        if ($('.js-phoneContextModal').length) {
            $('.js-phoneContextModal').magnificPopup({ type: 'inline' });
        }

        //------------------------------------------
        //  Review form Modal
        //------------------------------------------

        if ($('.js-reviewFormContextModal').length) {
            $('.js-reviewFormContextModal').magnificPopup({ type: 'inline' });
        }

        //------------------------------------------
        //  Press form Modal
        //------------------------------------------

        if ($('.js-formContextModal').length) {
            $('.js-formContextModal').magnificPopup({ type: 'inline' });
        }

        //--------------------------------------------------------------------
        //   Nav Site Search Toggle
        //--------------------------------------------------------------------
        $('.js-toggleSiteSearch').on('click', function (e) {
            e.preventDefault();
            toggleSiteSearch();
        })

        function toggleSiteSearch() {
            var $wrapper = $('.c-site-search'),
                $toggle = $('.c-site-search__toggle');

            $wrapper.toggleClass('is-active');
            $toggle.toggleClass('is-active');

            if ($wrapper.hasClass('is-active')) {
                $('.c-site-search__input').focus();
            }
        }

        //--------------------------------------------------------------------
        //   Podcast Transcript
        //--------------------------------------------------------------------
        if ($('.transcript__btn').length) {
            $(document).on('click', '.transcript__btn', function (e) {
                e.preventDefault();
                $('.transcript__wrapper').slideToggle(600, 'swing');
                $(this).text($(this).text() == 'Transcript -' ? 'Transcript +' : 'Transcript -');
            })
        }


        //--------------------------------------------------------------------
        //   Directory Toggle
        //--------------------------------------------------------------------
        if ($('.js-directory-tab').length) {
            $(document).on('click', '.js-directory-tab', function (e) {
                e.preventDefault();
                var ref = $(this).data("toggle");
                if ($(this).hasClass("is-active") === false) {
                    $('.c-directory__tab').toggleClass('is-active');
                    $('.c-directory__panel').toggleClass('is-active');
                }
            })
        }

        if ($('.js-directory-toggle').length) {
            $(document).on('click', '.js-directory-toggle', function (e) {
                e.preventDefault();
                var ref = $(this).data("toggle");
                if ($(this).hasClass("is-active") === false) {
                    $('.c-directory-browse__btn').removeClass("is-active");
                    $('.c-directory-browse__list').removeClass("is-active");
                    $(this).addClass("is-active");
                    $(ref).addClass("is-active");
                }
            })
        }


        /*
        //------------------------------------------
        //  Fact Check Modal
        //------------------------------------------

        if( $('.js-factCheckModal').length ) {
            $('.js-factCheckModal').magnificPopup({ type: 'inline' });
        }


        //------------------------------------------
        //  Hero JumpTo Mobile Expand
        //------------------------------------------

        $('.c-hero__bottom-menu').on('click', '.c-jump-to__title', function() {
            var $jumpTo = $(this).parents('.c-jump-to'),
                $jumpToList = $jumpTo.find('.c-jump-to__list');
            $jumpTo.toggleClass('is-active');
            $jumpToList.slideToggle();
        });
        */

        //------------------------------------------
        //  Simple Offer
        //------------------------------------------
        var SimpleOffer = {
            simpleOffer: {},
            buttons: {},
            closeButtons: {},
            simpleOfferId: 'simple-offer',
            simpleOfferModifier: 'is-active',
            buttonsClass: 'c-button',
            closeButtonsClass: 'c-simple__close',
            delay: 200,
            cookie: {
                name: 'simple_offer',
                value: true,
                exdays: 0.4,
                path: '/'
            },

            // check if the simple offer cookie has been set
            checkCookie: function () {
                return getCookie('simple_offer');
            },

            // set the simple offer cookie
            setCookie: function () {
                var cookie = this.cookie;
                setCookie(cookie.name, cookie.value, cookie.exdays, cookie.path);
            },
            // show the simple offer
            show: function () {
                this.simpleOffer.classList.add(this.simpleOfferModifier);
            },
            // hide the simple offer
            hide: function () {
                this.simpleOffer.classList.remove(this.simpleOfferModifier);
            },
            // get the simple offer element
            getSimpleOffer: function () {
                this.simpleOffer = document.getElementById(this.simpleOfferId);
            },
            // sets all the simple offer elements
            getButtons: function () {
                this.buttons = this.simpleOffer.getElementsByClassName(this.buttonsClass);
                this.closeButtons = document.getElementsByClassName(this.closeButtonsClass);
            },
            // check if the page has a simple offer
            enabled: function () {
                var enabled = this.simpleOffer ? true : false;
                return enabled;
            },
            init: function () {
                // get the simple offer elements
                this.getSimpleOffer();

                // get the buttons
                this.getButtons();

                //show simple offer after short delay
                setTimeout(function () {
                    SimpleOffer.show();
                }, this.delay);

                // add onclick functions to simple offer close buttons
                for (var i = 0; i < this.closeButtons.length; i++) {
                    this.closeButtons[i].onclick = function () {
                        var offer = SimpleOffer.buttons[0].getAttribute('href');
                        window.simpleOfferClosed(offer);
                        SimpleOffer.setCookie();
                        SimpleOffer.hide();
                    }
                }
            } //end init
        } //end SimpleOffer

        //check for simple offer cookie and elemnt before init. Less JS to run
        if (!getCookie('simple_offer') && document.getElementById("simple-offer")) {

            var timer;
            var startingPosition = $(document).scrollTop();//have a starting position so it won't load automattically on refresh or back button use

            //add scroll event listener so we can cleanup when no longer needed
            window.addEventListener('scroll', scrollListener);

            //fuction to check scroll position every 100ms and init the simple offer
            function scrollListener() {
                if (timer) {
                    window.clearTimeout(timer);
                }

                //function that runs every 100ms
                timer = window.setTimeout(function () {
                    //if window top is greater than 10% of document height, show simple offer
                    if ($(document).scrollTop() > startingPosition && $(document).scrollTop() > $(document).height() * 0.1) {
                        SimpleOffer.init();
                        window.removeEventListener('scroll', scrollListener);
                    }
                }, 100);
            }
        }

        /**
         * Sticky Sidebar
         */
        const stickyCtas = $('.c-cta--sticky');

        if (stickyCtas.length > 0) {
            // Dynamically get the sidebar height so we know when to show the CTA's
            const asideElm = $('.c-page__aside');
            const asideTopOffset = asideElm.offset().top;
            const paddingTop = parseInt(asideElm.css('padding-top'), 10);
            let sideColHeight = asideTopOffset + paddingTop;

            asideElm.children('div').not('.c-cta.c-cta--sticky').each((i, div) => {
                sideColHeight += $(div).outerHeight();
            });

            const stickyElms = [];
            let startingPosition = sideColHeight;
            let topPos = 45;
            let elementGap = 25;

            stickyCtas.each((i, stickyElm) => {
                const $stickyElm = $(stickyElm);
                const ctaHeight = $stickyElm.outerHeight();
                if (i > 0) {
                    startingPosition += ctaHeight;
                    topPos += $(stickyElms[i - 1].stickyElm).outerHeight() + elementGap;
                }

                // Calculate and set the bottom margin for each sticky element except the last one
                if (i < stickyCtas.length - 1) {
                    const nextStickyHeight = $(stickyCtas[i + 1]).outerHeight() + elementGap;
                    $stickyElm.css('margin-bottom', nextStickyHeight);
                }

                stickyElms.push({
                    stickyElm,
                    topPos,
                    startingPosition,
                });
            });

            // Sidebar CTA
            $(window).on('scroll', () => {
                const scrollPos = $(window).scrollTop();
                const footerOffsetTop = $(document).height() - $(window).height();

                stickyElms.forEach(({ stickyElm, topPos, startingPosition }) => {
                    if (scrollPos > startingPosition) {
                        $(stickyElm).css('top', topPos).addClass('is-active');
                    } else {
                        $(stickyElm).removeClass('is-active');
                    }

                    if ($('html').hasClass('no-csspositionsticky') &&
                        $(window).scrollTop() + $(window).height() > footerOffsetTop - startingPosition) {
                        $(stickyElm).removeClass('is-active');
                    }
                });
            });
        }

        //------------------------------------------
        // Sticky Banner
        //------------------------------------------

        // Sticky banner
        if ($('.c-banner').length > 0) {

            $('.js-deactivate').on('click', function (e) {
                $('.c-banner').addClass('is-disabled');
            });

            $(function () {
                var lastScrollTop = 1, delta = 15;

                $(window).scroll(function (event) {
                    var st = $(this).scrollTop();

                    if (!$('.c-banner').hasClass('is-disabled')) {
                        if (st > 600) {
                            if (Math.abs(lastScrollTop - st) <= delta)
                                return;
                            if ((st > lastScrollTop) && (lastScrollTop > 0)) {
                                // Scrolling Down
                                $('.c-banner').addClass('is-active');
                            } else {
                                // Scrolling Up
                                $('.c-banner').removeClass('is-active');
                            }
                            lastScrollTop = st;
                        } else {
                            // Hide within 600px of page top
                            $('.c-banner').removeClass('is-active');
                        }
                    }

                });
            });
        }

        //------------------------------------------
        //  Mobile Hub
        //------------------------------------------

        if ($('.c-mobile-hub__btn').length > 0) {

            // Mobile Hub appears
            $(window).scroll(function (event) {
                var st = $(this).scrollTop();

                if (st > 1400) {
                    $('.c-mobile-hub__btn').addClass('is-visible');
                } else {
                    // Hide within 600px of top of window
                    $('.c-mobile-hub__btn').removeClass('is-visible');
                }

                if ($(window).scrollTop() + $(window).height() > $(document).height() - 1400) {
                    // hide before footer
                    $('.c-mobile-hub__btn').removeClass('is-visible');
                }

            });

            // float button
            $('.c-mobile-hub__btn').on('click', function (e) {
                e.preventDefault();
                toggleMobileHub();
            });

            function toggleMobileHub() {
                $('body').toggleClass('c-mobile-hub--open');
                $('.c-mobile-hub__mask').toggleClass('is-active');
                $('.site-header').removeClass('is-fixed');

                // button
                $('.c-mobile-hub__ellipsis').toggleClass('is-open');

                // only sidebar
                $('.c-mobile-hub__sidebar').toggleClass('is-open');

                // close CTA if exists
                if ($('.c-banner')) {
                    $('.c-banner').removeClass('is-active');
                }
            }

            $('.c-mobile-hub__close').on('click', function (e) {
                e.preventDefault();
                toggleMobileHub();
            });

            // clicking a jump-to link
            $('.c-mobile-hub .c-jump-to__item a').on('click', function (e) {
                e.preventDefault();
                toggleMobileHub();
                window.location = this.href;//start jump to animation after hub closes
            });

            // clicking outside the element
            $(document).bind('mouseup touchend', function (e) {
                if ($('body').hasClass('c-mobile-hub--open')) {
                    // items within MH or Phone Context modal
                    var menuClick = $('.c-mobile-hub__sidebar, .c-mobile-hub__btn, .js-phoneContextModal, .mfp-content');

                    if (!menuClick.is(e.target) && menuClick.has(e.target).length === 0) {
                        $('.c-mobile-hub__btn').trigger('click');
                    }
                }
            });

        }

        //------------------------------------------
        //  FAQ List Component
        //------------------------------------------

        $('.c-faq__question').click(function (e) {
            e.preventDefault();
            $(this).toggleClass("c-faq__question--active").next().slideToggle();
        });

        //------------------------------------------
        //  Third-Party Advertiser Param
        //------------------------------------------

        if ($('.c-advertisement').length > 0) {
            // check for mixpanel id
            if (getCookie('mixp_id')) {
                var mp_id = getCookie('mixp_id');
                // Add the cookie value to the url param
                $('.c-advertisement').each(function (index) {
                    var advertisement_href = $(this).find("a"); // find the a tag
                    var _href = advertisement_href.attr("href");  // get the href value
                    // figure out if we need to kick of with a "?" or add to it with "&"
                    var param = (_href.indexOf("?") >= 0) ? '&campaign=' + mp_id : '?campaign=' + mp_id;
                    // set the url params to href
                    advertisement_href.attr("href", _href + param);
                });
            }
        }

        //------------------------------------------
        //    Clickable
        //    Note: Makes full Element clickable as a link (must have an a tag within)
        //------------------------------------------
        $(".clickable").click(function () {
            $(this).find("a")[0].click();
        });

        //------------------------------------------
        //    Show More Timeline
        //    Note: Makes full Element clickable as a link (must have an a tag within)
        //------------------------------------------

        if ($('.js-showTimeline').length > 0) {
            $('.js-showTimeline').click(function (e) {
                e.preventDefault();
                $(this).prev('.c-timeline--hide').removeClass('c-timeline--hide');
                $(this).hide();
            });
        }

        /**
         * Timeline Year Month Filter
         * timeline--year-month
         */
        let timeLineParent = $('.c-timeline');
        if (timeLineParent.hasClass('c-timeline--year-month')) {
            const timelineData = {
                years: new Set(),
                months: {},
                content: {}
            };
            const monthList = ['January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'];

            // Parse timeline items
            $('.c-timeline__item').each(function() {
                const titleText = $(this).find('.c-timeline__item-title').text().trim();
                const content = $(this).find('.c-timeline__item-content').html();
                const match = titleText.match(/(\w+)(?:\s+\d{1,2},)?\s+(\d{4}):?/);
                if (match) {
                    const [, month, year] = match;
                    timelineData.years.add(year);

                    // Create year/month structure
                    if (!timelineData.months[year]) {
                        timelineData.months[year] = new Set();
                        timelineData.content[year] = {};
                    }

                    // Add month to year
                    timelineData.months[year].add(month);

                    // Store content
                    if (!timelineData.content[year][month]) {
                        timelineData.content[year][month] = [];
                    }
                    timelineData.content[year][month].push(content);
                }
            });

            // New structure
            const $container = $('<div class="c-timeline-filter timeline--year-month"></div>');
            const $yearTabs = $('<div class="c-timeline-filter__years"></div>');
            const $monthList = $('<div class="c-timeline-filter__months"></div>');
            const $content = $('<div class="c-timeline-filter__content"></div>');

            // Add year tabs
            [...timelineData.years].sort().reverse().forEach(year => {
                $yearTabs.append(`<button class="c-timeline-filter__year" data-year="${year}">${year}</button>`);
            });

            $('.c-timeline').replaceWith($container);
            $container.append($yearTabs, $monthList, $content);

            // Event Handlers
            function showMonthsForYear(year) {
                $monthList.empty();
                [...timelineData.months[year]].sort((a, b) => {
                    return monthList.indexOf(a) - monthList.indexOf(b);
                }).reverse().forEach(month => {
                    $monthList.append(`<button class="c-timeline-filter__month" data-year="${year}" data-month="${month}">${month}</button>`);
                });
            }

            function showContent(year, month) {
                $content.empty();
                timelineData.content[year][month].forEach(content => {
                    $content.append(`<div class="c-timeline-filter__content-item">${content}</div>`);
                });
            }

            function getSortedMonths(year) {
                return [...timelineData.months[year]].sort((a, b) => {
                    return monthList.indexOf(b) - monthList.indexOf(a);
                });
            }

            function getLatestMonth(year) {
                return getSortedMonths(year)[0];
            }

            function selectLatestMonth(year) {
                const latestMonth = getLatestMonth(year);
                if (latestMonth) {
                    $(`.c-timeline-filter__month[data-year="${year}"][data-month="${latestMonth}"]`)
                        .addClass('active')
                        .trigger('click');
                }
            }

            // Click handlers
            $(document).on('click', '.c-timeline-filter__year', function() {
                const year = $(this).data('year');
                $('.c-timeline-filter__year').removeClass('active');
                $(this).addClass('active');
                showMonthsForYear(year);
                selectLatestMonth(year);
            });

            $(document).on('click', '.c-timeline-filter__month', function() {
                const year = $(this).data('year');
                const month = $(this).data('month');
                $('.c-timeline-filter__month').removeClass('active');
                $(this).addClass('active');
                showContent(year, month);
            });

            // Show initial state (latest year and latest month)
            const latestYear = Math.max(...timelineData.years);
            $(`.c-timeline-filter__year[data-year="${latestYear}"]`).trigger('click');
        }

        if ($('div.bggrey-noshadow').length) {
            setCarouselEqualHeights();
            $(window).on('resize',debounce(setCarouselEqualHeights, 250));
            $('.jsCarousel').on('afterChange', setCarouselEqualHeights);
        }
    }); // End of Doc Ready

    /**
     * Sets the height of all paragraphs within the carousel container to be equal,
     * using the tallest height among them.
     *
     * @used In `.c-carousel` with `.bggrey-noshadow` modifier
     * @return {void}
     */
    function setCarouselEqualHeights() {
        let elements = $('.c-carousel__content > p', 'div.bggrey-noshadow')
        const heights = elements.css('height', 'auto').map(function() {
            return $(this).outerHeight();
        }).get();

        elements.height(Math.max(...heights));
    }

    /**
     * Creates a debounced function that delays invoking the provided function
     * until after the specified `wait` time has elapsed since the last time the
     * debounced function was called.
     *
     * @param {Function} func - The function to debounce.
     * @param {number} wait - The number of milliseconds to delay.
     * @returns {Function} - A debounced version of the provided function.
     */
    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }

})(jQuery);
